@tailwind base;
@tailwind components;
@tailwind utilities;

.darken-on-hover {
  transition: filter 0.15s ease-in-out;
}

.darken-on-hover:hover {
  filter: brightness(0.9);
}

.bigcard:hover .deleteicon {
  display: block;
}

.bigcard:hover .readmore {
  display: inline;
}

.readmore {
  display: none;
}


.deleteicon {
  display: none;
}